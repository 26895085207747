/*= ========================================================================================
  File Name: store.js
  Description: Vuex store
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== */
import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import createPersistedState from 'vuex-persistedstate'
// import { getAPI } from '@/axios-api'
Vue.use(Vuex)
export default new Vuex.Store({
  state: {
    role: '',
    token: '',
    isAuthenticated: false,
    isLoading: false,
    APIData: '',
  },
  mutations: {
    updateStorage(state, { token }) {
      state.token = token
      state.isAuthenticated = true
      // state.refreshToken = refresh
    },
    destroyToken(state) {
      state.token = ''
      state.isAuthenticated = false
      // state.refreshToken = null
    },
    setIsLoading(state, status) {
      state.isLoading = status
    },
    setToken(state, token) {
      state.token = token
      state.isAuthenticated = true
    },
    setRole(state, role) {
      state.role = role
    },
    initializeStore(state) {
      if (localStorage.getItem('token')) {
        state.token = localStorage.getItem('token')
        state.isAuthenticated = true
      } else {
        state.token = ''
        state.isAuthenticated = false
      }
    },
    removeToken(state) {
      state.role = ''
      state.token = ''
      state.isAuthenticated = false
    },
  },
  getters: {
    loggedIn(state) {
      return state.token != null
    },
  },
  actions: {
    userLogout(context) {
      if (context.getters.loggedIn) {
        context.commit('destroyToken')
        localStorage.clear()
      }
    },
    userLogin(context, usercredentials) {
      axios.defaults.headers.common.Authorization = ''
      return new Promise((resolve, reject) => {
        axios.post('/api/jwt/create/', {
          username: usercredentials.username,
          password: usercredentials.password,
        })
          .then(response => {
            const { access } = response.data
            context.commit('setToken', access)
            // context.commit('setRole', user_type)
            axios.defaults.headers.common.Authorization = `Token ${access}`
            localStorage.setItem('token', access)
            // context.commit('updateStorage', { token: response.data.token })
            resolve()
          })
          .catch(err => {
            reject(err)
          })
      })
    },
  },
  strict: process.env.NODE_ENV !== 'production',
  modules: {

  },
  plugins: [createPersistedState()],
})
