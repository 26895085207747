import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store/store'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'dashboard',
      props: true,
      component: () => import('@/views/dashboard/Date.vue'),
      meta: {
        layout: 'vertical',
        requireLogin: true,
        pageTitle: 'Etat de vente',
        breadcrumb: [
          {
            text: 'Etat de vente',
            active: true,
          },
        ],
      },
    },
    {
      path: '/reporting',
      name: 'reporting',
      props: true,
      // eslint-disable-next-line import/no-unresolved
      component: () => import('@/views/dashboard/Reporting.vue'),
      meta: {
        layout: 'vertical',
        requireLogin: true,
        pageTitle: 'Reporting',
        breadcrumb: [
          {
            text: 'Products Value',
            active: true,
          },
        ],
      },
    },
    {
      path: '/stock-alert',
      name: 'stock-alert',
      props: true,
      // eslint-disable-next-line import/no-unresolved
      component: () => import('@/views/dashboard/StockAlert.vue'),
      meta: {
        layout: 'vertical',
        requireLogin: true,
        pageTitle: 'Stock Alert',
        breadcrumb: [
          {
            text: 'Stock Alert',
            active: true,
          },
        ],
      },
    },
    {
      path: '/dashboard/date-a-date',
      name: 'dashboard-date-to-date',
      props: true,
      component: () => import('@/views/dashboard/DateToDate.vue'),
      meta: {
        layout: 'vertical',
        requireLogin: true,
        pageTitle: 'Etat de vente',
        breadcrumb: [
          {
            text: 'Etat de vente',
            active: true,
          },
        ],
      },
    },
    {
      path: '/dashboard/retour/date',
      name: 'return-date',
      props: true,
      component: () => import('@/views/dashboard/ReturnDate.vue'),
      meta: {
        layout: 'vertical',
        requireLogin: true,
        pageTitle: 'Etat de vente',
        breadcrumb: [
          {
            text: 'Etat de vente',
            active: true,
          },
        ],
      },
    },
    {
      path: '/dashboard/retour/date-to-date',
      name: 'return-date-to-date',
      props: true,
      component: () => import('@/views/dashboard/ReturnDateToDate.vue'),
      meta: {
        layout: 'vertical',
        requireLogin: true,
        pageTitle: 'Etat de vente',
        breadcrumb: [
          {
            text: 'Etat de vente',
            active: true,
          },
        ],
      },
    },
    {
      path: '/login',
      name: 'auth',
      component: () => import('@/views/Authentification/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/logout',
      name: 'logout',
      component: () => import('@/views/Authentification/Logout.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/register',
      name: 'register',
      component: () => import('@/views/Authentification/Register.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
    // gouvernorat crud
    {
      path: '/devis',
      name: 'devis-list',
      props: true,
      component: () => import('@/views/devis/Index.vue'),
      meta: {
        requireLogin: true,
        pageTitle: 'Liste des Devis',
        breadcrumb: [
          {
            text: 'Devis',
          },
          {
            text: 'Liste des Devis',
            active: true,
          },
        ],
      },
    },
    {
      path: '/devis/create',
      name: 'devis-add',
      props: true,
      component: () => import('@/views/devis/Create.vue'),
      meta: {
        requireLogin: true,
        pageTitle: 'Ajouter Devis',
        breadcrumb: [
          {
            text: 'Devis',
          },
          {
            text: 'Ajouter Devis',
            active: true,
          },
        ],
      },
    },

    {
      path: '/devis/edit/:id',
      name: 'devis-edit',
      props: true,
      component: () => import('@/views/devis/Edit.vue'),
      meta: {
        requireLogin: true,
        pageTitle: 'Modifier Devis',
        breadcrumb: [
          {
            text: 'Devis',
          },
          {
            text: 'Modifier Devis',
            active: true,
          },
        ],
      },
    },
    {
      path: '/devis/show/:id',
      name: 'devis-show',
      props: true,
      component: () => import('@/views/devis/Show.vue'),
      meta: {
        requireLogin: true,

      },
    },
    // facture crud
    {
      path: '/factures',
      name: 'facture-list',
      props: true,
      component: () => import('@/views/factures/Index.vue'),
      meta: {
        requireLogin: true,
        pageTitle: 'Liste des factures',
        breadcrumb: [
          {
            text: 'Factures',
          },
          {
            text: 'Liste des factures',
            active: true,
          },
        ],
      },
    },
    {
      path: '/factures/preview/:id',
      name: 'facture-preview',
      props: true,
      component: () => import('@/views/factures/Preview.vue'),
      meta: {
        requireLogin: true,
        pageTitle: 'Ajouter factures',
        breadcrumb: [
          {
            text: 'Factures',
          },
          {
            text: 'Ajouter factures',
            active: true,
          },
        ],
      },
    },
    {
      path: '/factures/preview-delivery-note/:id',
      name: 'facture-delivery-preview',
      props: true,
      component: () => import('@/views/factures/PreviewDelivery.vue'),
      meta: {
        requireLogin: true,
        pageTitle: 'Ajouter factures',
        breadcrumb: [
          {
            text: 'Factures',
          },
          {
            text: 'Ajouter factures',
            active: true,
          },
        ],
      },
    },
    {
      path: '/factures/create',
      name: 'facture-add',
      props: true,
      component: () => import('@/views/factures/Create.vue'),
      meta: {
        requireLogin: true,
        pageTitle: 'Ajouter Facture',
        breadcrumb: [
          {
            text: 'Factures',
          },
          {
            text: 'Ajouter facture',
            active: true,
          },
        ],
      },
    },

    {
      path: '/factures/edit/:id',
      name: 'facture-edit',
      props: true,
      component: () => import('@/views/factures/Edit.vue'),
      meta: {
        requireLogin: true,
        pageTitle: 'Modifier facture',
        breadcrumb: [
          {
            text: 'Factures',
          },
          {
            text: 'Modifier facture',
            active: true,
          },
        ],
      },
    },

    {
      path: '/factures/show/:id',
      name: 'facture-show',
      props: true,
      component: () => import('@/views/factures/Show.vue'),
      meta: {
        requireLogin: true,
      },
    },

    // avoir crud
    {
      path: '/avoirs',
      name: 'avoir-list',
      props: true,
      component: () => import('@/views/avoirs/Index.vue'),
      meta: {
        requireLogin: true,
        pageTitle: 'Liste des avoirs',
        breadcrumb: [
          {
            text: 'Avoirs',
          },
          {
            text: 'Liste des Avoirs',
            active: true,
          },
        ],
      },
    },

    {
      path: '/avoirs/create',
      name: 'avoir-add',
      props: true,
      component: () => import('@/views/avoirs/Create.vue'),
      meta: {
        requireLogin: true,
        pageTitle: 'Ajouter avoir',
        breadcrumb: [
          {
            text: 'Avoirs',
          },
          {
            text: 'Ajouter avoir',
            active: true,
          },
        ],
      },
    },

    {
      path: '/avoirs/edit/:id',
      name: 'avoir-edit',
      props: true,
      component: () => import('@/views/avoirs/Edit.vue'),
      meta: {
        requireLogin: true,
        pageTitle: 'Modifier avoir',
        breadcrumb: [
          {
            text: 'Avoirs',
          },
          {
            text: 'Modifier avoir',
            active: true,
          },
        ],
      },
    },

    {
      path: '/avoirs/show/:id',
      name: 'avoir-show',
      props: true,
      component: () => import('@/views/avoirs/Show.vue'),
      meta: {
        requireLogin: true,
      },
    },
    // return voucher crud
    {
      path: '/retours',
      name: 'return-voucher-list',
      props: true,
      component: () => import('@/views/returnVouchers/Index.vue'),
      meta: {
        requireLogin: true,
        pageTitle: 'Liste des bons de retour',
        breadcrumb: [
          {
            text: 'Bon de Retour',
          },
          {
            text: 'Liste des bons de Retour',
            active: true,
          },
        ],
      },
    },

    {
      path: '/retours/create',
      name: 'return-voucher-add',
      props: true,
      component: () => import('@/views/returnVouchers/Create.vue'),
      meta: {
        requireLogin: true,
        pageTitle: 'Ajouter Bon de Retour',
        breadcrumb: [
          {
            text: 'Bon de Retour',
          },
          {
            text: 'Ajouter bon de retour',
            active: true,
          },
        ],
      },
    },

    {
      path: '/retours/edit/:id',
      name: 'return-voucher-edit',
      props: true,
      component: () => import('@/views/returnVouchers/Edit.vue'),
      meta: {
        requireLogin: true,
        pageTitle: 'Modifier bon de retour',
        breadcrumb: [
          {
            text: 'Bon de Retour',
          },
          {
            text: 'Modifier bon de retour',
            active: true,
          },
        ],
      },
    },

    {
      path: '/retours/show/:id',
      name: 'return-voucher-show',
      props: true,
      component: () => import('@/views/returnVouchers/Show.vue'),
      meta: {
        requireLogin: true,
      },
    },
    // commande passager
    {
      path: '/commandes-passagers',
      name: 'commande-list',
      props: true,
      component: () => import('@/views/commandes/Index.vue'),
      meta: {
        requireLogin: true,
        pageTitle: 'Historique de vente',
        breadcrumb: [
          {
            text: 'Ventes',
          },
          {
            text: 'Historique de vente',
            active: true,
          },
        ],
      },
    },
    {
      path: '/commandes-passagers/create',
      name: 'commande-passager-add',
      props: true,
      component: () => import('@/views/commandePassagers/Create.vue'),
      meta: {
        requireLogin: true,
        pageTitle: 'Vente direct / en ligne',
        breadcrumb: [
          {
            text: 'Ventes',
          },
          {
            text: 'Vente direct / en ligne',
            active: true,
          },
        ],
      },
    },

    {
      path: '/commandes-passagers/edit/:id',
      name: 'commande-passager-edit',
      props: true,
      component: () => import('@/views/commandePassagers/Edit.vue'),
      meta: {
        requireLogin: true,
        pageTitle: 'Modifier Vente',
        breadcrumb: [
          {
            text: 'Vente direct/en ligne',
          },
          {
            text: 'Modifier Vente',
            active: true,
          },
        ],
      },
    },

    {
      path: '/commandes/show/:id',
      name: 'commande-show',
      props: true,
      component: () => import('@/views/commandes/Show.vue'),
      meta: {
        requireLogin: true,
      },
    },

    {
      path: '/commandes-gros/create',
      name: 'commande-gros-add',
      props: true,
      component: () => import('@/views/commandeGros/create.vue'),
      meta: {
        requireLogin: true,
        pageTitle: 'Vente Gros',
        breadcrumb: [
          {
            text: 'Ventes',
          },
          {
            text: 'Vente Gros',
            active: true,
          },
        ],
      },
    },
    {
      path: '/commandes-gros/edit/:id',
      name: 'commande-gros-edit',
      props: true,
      component: () => import('@/views/commandeGros/Edit.vue'),
      meta: {
        requireLogin: true,
        pageTitle: 'Modifier vente',
        breadcrumb: [
          {
            text: 'Ventes gros',
          },
          {
            text: 'Modifier vente',
            active: true,
          },
        ],
      },
    },
    // bon de livraison crud
    {
      path: '/bons-livraisons',
      name: 'bon-livraison-list',
      props: true,
      component: () => import('@/views/deliveryNotes/Index.vue'),
      meta: {
        requireLogin: true,
        pageTitle: 'Liste des bons de livraison',
        breadcrumb: [
          {
            text: 'Bon de livraison',
          },
          {
            text: 'Liste des bons de livraisons',
            active: true,
          },
        ],
      },
    },
    {
      path: '/bons-livraisons/create',
      name: 'bon-livraison-add',
      props: true,
      component: () => import('@/views/deliveryNotes/Create.vue'),
      meta: {
        requireLogin: true,
        pageTitle: 'Ajouter bon de livraison',
        breadcrumb: [
          {
            text: 'Bon de livraison',
          },
          {
            text: 'Ajouter Bon de livraison',
            active: true,
          },
        ],
      },
    },

    {
      path: '/bons-livraisons/edit/:id',
      name: 'bon-livraison-edit',
      props: true,
      component: () => import('@/views/deliveryNotes/Edit.vue'),
      meta: {
        requireLogin: true,
        pageTitle: 'Modifier Bon de livraison',
        breadcrumb: [
          {
            text: 'Bon de livraison',
          },
          {
            text: 'Modifier Bon de livraison',
            active: true,
          },
        ],
      },
    },

    {
      path: '/bons-livraisons/show/:id',
      name: 'bon-livraison-show',
      props: true,
      component: () => import('@/views/deliveryNotes/Show.vue'),
      meta: {
        requireLogin: true,

      },
    },
    // depot crud
    {
      path: '/clients',
      name: 'client-list',
      props: true,
      component: () => import('@/views/clients/Index.vue'),
      meta: {
        requireLogin: true,
        pageTitle: 'Liste des clients',
        breadcrumb: [
          {
            text: 'Client',
          },
          {
            text: 'Liste des client',
            active: true,
          },
        ],
      },
    },
    {
      path: '/clients/create',
      name: 'client-add',
      props: true,
      component: () => import('@/views/clients/Create.vue'),
      meta: {
        requireLogin: true,
        pageTitle: 'Ajouter client',
        breadcrumb: [
          {
            text: 'Clients',
          },
          {
            text: 'Ajouter client',
            active: true,
          },
        ],
      },
    },
    {
      path: '/clients/edit/:id',
      name: 'client-edit',
      props: true,
      component: () => import('@/views/clients/Edit.vue'),
      meta: {
        requireLogin: true,
        pageTitle: 'Modifier client',
        breadcrumb: [
          {
            text: 'Client',
          },
          {
            text: 'Modifier client',
            active: true,
          },
        ],
      },
    },
    // utilisateur crud
    {
      path: '/utilisateurs',
      name: 'utilisateur-list',
      props: true,
      component: () => import('@/views/utilisateurs/Index.vue'),
      meta: {
        requireLogin: true,
        pageTitle: 'Liste des utilisateurs',
        breadcrumb: [
          {
            text: 'Utilisateurs',
          },
          {
            text: 'Liste des utilisateurs',
            active: true,
          },
        ],
      },
    },
    {
      path: '/utilisateurs/create',
      name: 'utilisateur-add',
      props: true,
      component: () => import('@/views/utilisateurs/Create.vue'),
      meta: {
        requireLogin: true,
        pageTitle: 'Ajouter utilisateur',
        breadcrumb: [
          {
            text: 'Utilisateurs',
          },
          {
            text: 'Ajouter utilisateur',
            active: true,
          },
        ],
      },
    },

    {
      path: '/utilisateurs/edit/:id',
      name: 'utilisateur-edit',
      props: true,
      component: () => import('@/views/utilisateurs/Edit.vue'),
      meta: {
        requireLogin: true,
        pageTitle: 'Modifier utilisateur',
        breadcrumb: [
          {
            text: 'Utilisateurs',
          },
          {
            text: 'Modifier utilisateur',
            active: true,
          },
        ],
      },
    },

    {
      path: '/utilisateurs/show/:id',
      name: 'utilisateur-show',
      props: true,
      component: () => import('@/views/utilisateurs/Show.vue'),
      meta: {
        requireLogin: true,
        pageTitle: 'Détails utilisateurs',
        breadcrumb: [
          {
            text: 'Utilisateurs',
          },
          {
            text: 'Détails utilisateurs',
            active: true,
          },
        ],
      },
    },

    // produit crud
    {
      path: '/produits',
      name: 'produit-list',
      props: true,
      component: () => import('@/views/produits/Index.vue'),
      meta: {
        requireLogin: true,
        pageTitle: 'Liste des produits',
        breadcrumb: [
          {
            text: 'Produits',
          },
          {
            text: 'Liste des produits',
            active: true,
          },
        ],
      },
    },
    {
      path: '/produits/create',
      name: 'produit-add',
      props: true,
      component: () => import('@/views/produits/Create.vue'),
      meta: {
        requireLogin: true,
        pageTitle: 'Ajouter produit',
        breadcrumb: [
          {
            text: 'Produits',
          },
          {
            text: 'Ajouter produit',
            active: true,
          },
        ],
      },
    },

    {
      path: '/produits/import-stock-labo',
      name: 'import-stock-labo',
      props: true,
      component: () => import('@/views/produits/ImportStockLabo.vue'),
      meta: {
        requireLogin: true,
        pageTitle: 'Importer stock labo',
        breadcrumb: [
          {
            text: 'Produits',
          },
          {
            text: 'Importer stock labo',
            active: true,
          },
        ],
      },
    },

    {
      path: '/produits/edit/:id',
      name: 'produit-edit',
      props: true,
      component: () => import('@/views/produits/Edit.vue'),
      meta: {
        requireLogin: true,
        pageTitle: 'Modifier produits',
        breadcrumb: [
          {
            text: 'Produits',
          },
          {
            text: 'Modifier produit',
            active: true,
          },
        ],
      },
    },

    {
      path: '/produits/show/:id',
      name: 'produit-show',
      props: true,
      component: () => import('@/views/produits/Show.vue'),
      meta: {
        requireLogin: true,
        pageTitle: 'Détails produits',
        breadcrumb: [
          {
            text: 'Produits',
          },
          {
            text: 'Détails produits',
            active: true,
          },
        ],
      },
    },

  ],
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requireLogin) && store.state.isAuthenticated === false) {
    next({ name: 'auth' })
  // eslint-disable-next-line eqeqeq
  } else if (to.matched.some(record => record.meta.requireLogin) && to.matched.some(record => record.meta.admin) && store.state.role == false) {
    next({ name: 'error-404' })
  } else {
    next()
  }
})

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
